export const humanFileSize = ( bytes: number, decimal: number = 1) => {
  if (Math.abs(bytes) < 1024) {
    return bytes + ' B'
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let i = -1
  const r = 10**decimal

  do {
    bytes /= 1024
    i++
  } while(Math.round(Math.abs(bytes) * r) / r >= 1024 && i < units.length - 1)

  return bytes.toFixed(decimal) + ' ' + units[i];
}
